import React, {useEffect} from 'react';
import TokenPageLink from '@/view/components/TokenPageLink';
import styles from '@/view/styles/components/PostCard/blocs/PostTextCard.module.scss';
import clsx from 'clsx';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import Tooltip from '../../Tooltip';
import {UserItem} from '../../UserItem';
import {updateTimeSince} from '@/utils/timer';
import {format} from 'timeago.js';
import TextClamp from '../../TextClamp';
import {PostTextCardTokenFragment$key} from './__generated__/PostTextCardTokenFragment.graphql';
import TimestampTooltip from '../../TimestampTooltip';

const PostTextCardTokenFragment = graphql`
  fragment PostTextCardTokenFragment on tezos_tokens {
    id
    nodeId
    title
    description
    thumbnail_uri
    created_at
    tokens_sharesCollection {
      edges {
        node {
          share_count
        }
      }
    }
    accounts {
      identities {
        profilesCollection {
          edges {
            node {
              username
            }
          }
        }
      }
      ...UserItemFragment
    }
  }
`;

export default function PostTextCard({
  tokenKey,
}: {
  tokenKey: PostTextCardTokenFragment$key;
}) {
  const token = useFragment(PostTextCardTokenFragment, tokenKey);

  const [age, setAge] = React.useState('');

  useEffect(() => {
    return updateTimeSince(() => {
      const formatted = format(
        new Date(token.created_at).getTime() || Date.now()
      );
      const age = formatted
        .replace(' ago', '')
        .replace(
          /(\d+) (second|minute|hour|day|week|month|year)s?/g,
          (_, count, unit) => {
            return `${count}${unit === 'month' ? 'mo' : unit[0]}`;
          }
        );
      setAge(age);
    });
  }, [token.created_at]);

  return (
    <TokenPageLink
      tokenId={token.id}
      username={
        token.accounts.identities.profilesCollection?.edges?.[0]?.node
          ?.username || undefined
      }
      className={styles.textCard}
    >
      <div>
        <div className={styles.header}>
          <div>
            {token?.accounts && (
              <UserItem account={token.accounts} hideText showFollowButton />
            )}
            <div>
              <div>
                {token?.accounts && (
                  <UserItem account={token.accounts} hideIcon />
                )}
              </div>
              <div>
                <TimestampTooltip
                  date={token.created_at}
                  className={styles.age}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            styles.content,
            token.description !== null &&
              token.description.length < 25 &&
              styles.biggerText
          )}
        >
          <TextClamp
            text={token.description || ''}
            prefix={null}
            maxChars={300}
            enableMentions
            hideMore
          />
        </div>
      </div>
    </TokenPageLink>
  );
}
