/**
 * @generated SignedSource<<fc8bae710d31c6b098909167008fe967>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostDefaultCardTokenFragment$data = {
  readonly accounts: {
    readonly id: string;
    readonly identities: {
      readonly profilesCollection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly username: string | null;
          };
        }>;
      } | null;
    };
    readonly nodeId: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserProfileItemFragment">;
  };
  readonly artifact_uri: string;
  readonly description: string | null;
  readonly display_uri: string;
  readonly editions: string;
  readonly editions_minted: string | null;
  readonly id: string;
  readonly mime_type: string;
  readonly nodeId: string;
  readonly salesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly amount: string;
        readonly price: number;
        readonly sale_id: string | null;
      };
    }>;
  } | null;
  readonly thumbnail_uri: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"CollectTokenFragment" | "PostActionsViewOnlyTokenFragment">;
  readonly " $fragmentType": "PostDefaultCardTokenFragment";
};
export type PostDefaultCardTokenFragment$key = {
  readonly " $data"?: PostDefaultCardTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostDefaultCardTokenFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostDefaultCardTokenFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "artifact_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "display_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mime_type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editions_minted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "identities",
          "kind": "LinkedField",
          "name": "identities",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "profilesConnection",
              "kind": "LinkedField",
              "name": "profilesCollection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profilesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "profiles",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "username",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserProfileItemFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "salesConnection",
      "kind": "LinkedField",
      "name": "salesCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "salesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "sales",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sale_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostActionsViewOnlyTokenFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectTokenFragment"
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};
})();

(node as any).hash = "44c02186367dd155896fcc187f72bc7f";

export default node;
