import React, {Suspense} from 'react';
import TokenPageLink from '../../TokenPageLink';
import Skeleton from '../../Skeleton';
import Image from '../../Image';
import clsx from 'clsx';
import Router from 'next/router';
import Tooltip from '../../Tooltip';
import Collect, {CollectButtonSkeleton} from '../../CollectDialog/Collect';
import UserProfileItem from '../../UserProfileItem';
import styles from '@/view/styles/components/PostCard/blocs/PostDefaultCard.module.scss';
import {convertGqlMimeTypetoMimeType} from '@/utils/conversions/mimeTypeConversions';
import {getImageUrl} from '@/utils/conversions/conversions';
import PostActionsViewOnly from '../../PostActions/blocs/PostActionsViewOnly';
import {MimeType} from '@/view/types/types';
import RemixIcon from '../../RemixIcon';
import {PostDefaultCardTokenFragment$key} from './__generated__/PostDefaultCardTokenFragment.graphql';
import {graphql, useFragment} from '@/kits/relay-kit/src';

const PostDefaultCardTokenFragment = graphql`
  fragment PostDefaultCardTokenFragment on tezos_tokens {
    id
    nodeId
    title
    description
    artifact_uri
    thumbnail_uri
    display_uri
    mime_type
    editions
    editions_minted
    accounts {
      id
      nodeId
      identities {
        profilesCollection {
          edges {
            node {
              username
            }
          }
        }
      }
      ...UserProfileItemFragment
    }
    salesCollection {
      edges {
        node {
          sale_id
          price
          amount
        }
      }
    }
    ...PostActionsViewOnlyTokenFragment
    ...CollectTokenFragment
  }
`;

export default function PostDefaultCard({
  tokenKey,
  view = 'default',
  ownedCount,
}: {
  tokenKey: PostDefaultCardTokenFragment$key;
  view?: 'simple' | 'default' | 'default-library';
  ownedCount?: number;
}) {
  const token = useFragment(PostDefaultCardTokenFragment, tokenKey);
  const isTextPost =
    convertGqlMimeTypetoMimeType(token?.mime_type || undefined) ===
    MimeType.TEXT;

  const [loadingImage, setLoadingImage] = React.useState(true);

  const editionsLeft =
    parseInt(token.editions) - parseInt(token.editions_minted || '0');

  const isPlayable =
    convertGqlMimeTypetoMimeType(token.mime_type) === MimeType.AUDIO ||
    convertGqlMimeTypetoMimeType(token.mime_type) === MimeType.VIDEO;

  return (
    <div
      className={styles.defaultWrapper}
      data-view={view === 'default-library' ? 'default' : view}
    >
      <TokenPageLink
        tokenId={token.id}
        username={
          token.accounts.identities.profilesCollection?.edges?.[0]?.node
            ?.username || undefined
        }
      >
        <div className={styles.root}>
          <div
            className={clsx(
              styles.card,
              view === 'simple' && styles.simpleCard
            )}
            key={token.id}
          >
            {loadingImage && (
              <Skeleton
                variant="rect"
                animation="wave"
                className={styles.backgroundSkeleton}
              />
            )}
            <Image
              dynamic
              src={getImageUrl(
                (isTextPost ? token.display_uri : token.thumbnail_uri) || ''
              )}
              alt=""
              onLoad={() => {
                setLoadingImage(false);
              }}
              className={clsx(styles.background)}
              onError={e => {
                e.currentTarget.src = '/poster.svg';
                setLoadingImage(false);
              }}
              key={token.id}
              observe
            />
            <div className={styles.overlay} />
            {isPlayable && (
              <RemixIcon
                icon="play-fill"
                size={24}
                className={styles.playButton}
              />
            )}
            <PostActionsViewOnly
              post={token}
              labels
              vertical
              className={styles.actions}
            />
          </div>
        </div>
      </TokenPageLink>
      {view === 'simple' && (
        <UserProfileItem
          user={token.accounts}
          className={styles.avatar}
          hideText
        />
      )}
      {(view === 'default' || view === 'default-library') && (
        <div className={styles.details}>
          <div>
            <span>{token.title}</span>
            {view === 'default-library' ? (
              ownedCount !== undefined ? (
                <span>
                  <span>{ownedCount}</span>/{token.editions}
                </span>
              ) : (
                <span>x{token.editions}</span>
              )
            ) : null}
          </div>
          <div>
            {view === 'default' && (
              <Suspense fallback={<CollectButtonSkeleton />}>
                <Collect token={token} priceOnly />
              </Suspense>
            )}
            {view === 'default-library' ? (
              <div className={styles.creator}>
                <UserProfileItem hideIcon prefix="by" user={token.accounts} />
              </div>
            ) : (
              <Tooltip text="Collectors">
                <span
                  onClick={() => {
                    Router.push(
                      {pathname: Router.pathname, query: {...Router.query}},
                      Router.asPath + '#',
                      {
                        shallow: true,
                        scroll: false,
                      }
                    );
                  }}
                  data-sold-out={parseInt(token.editions) === 0}
                >
                  {editionsLeft + '/' + parseInt(token.editions)}
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
