import React from 'react';
import PostTextCard from './blocs/PostTextCard';
import PostDefaultCard from './blocs/PostDefaultCard';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import PostMinimalCard from './blocs/PostMinimalCard';
import PostMusicCard from './blocs/PostMusicCard';

import {PostCardTokenFragment$key} from './__generated__/PostCardTokenFragment.graphql';

const PostCardTokenFragment = graphql`
  fragment PostCardTokenFragment on tezos_tokens
  @argumentDefinitions(
    includeText: {type: "Boolean", defaultValue: false}
    includeMinimal: {type: "Boolean", defaultValue: false}
    includeMusic: {type: "Boolean", defaultValue: false}
    includeDefault: {type: "Boolean", defaultValue: false}
  ) {
    id
    nodeId
    title
    description
    thumbnail_uri
    tokens_sharesCollection {
      edges {
        node {
          share_count
        }
      }
    }
    accounts {
      identities {
        profilesCollection {
          edges {
            node {
              username
            }
          }
        }
      }
    }
    ...PostTextCardTokenFragment @include(if: $includeText)
    ...PostMinimalCardTokenFragment @include(if: $includeMinimal)
    ...PostMusicCardTokenFragment @include(if: $includeMusic)
    ...PostDefaultCardTokenFragment @include(if: $includeDefault)
  }
`;
export default function PostCard({
  tokenKey,
  view = 'default',
  ownedCount,
}: {
  tokenKey: PostCardTokenFragment$key;
  view?:
    | 'simple'
    | 'text'
    | 'default'
    | 'default-library'
    | 'minimal'
    | 'music';
  ownedCount?: number;
}) {
  const token = useFragment(
    PostCardTokenFragment, // The imported graphql fragment query
    tokenKey // The props
  );

  if (view === 'text') {
    return <PostTextCard tokenKey={token} />;
  }

  if (view === 'minimal') {
    return <PostMinimalCard tokenKey={token} />;
  }

  if (view === 'music') {
    return <PostMusicCard tokenKey={token} />;
  }

  return (
    <PostDefaultCard tokenKey={token} view={view} ownedCount={ownedCount} />
  );
}
