import React from 'react';
import TokenPageLink from '../../TokenPageLink';
import Button from '../../Button';
import {MimeType} from '@/view/types/types';
import {MemoIcon} from '../../utils/MemoChildren';
import styles from '@/view/styles/components/PostCard/blocs/PostMusicCard.module.scss';
import {convertGqlMimeTypetoMimeType} from '@/utils/conversions/mimeTypeConversions';
import {getImageUrl} from '@/utils/conversions/conversions';
import {Avatar} from '../../Avatar';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {PostMusicCardTokenFragment$key} from './__generated__/PostMusicCardTokenFragment.graphql';
import {UserItem} from '../../UserItem';

const PostMusicCardTokenFragment = graphql`
  fragment PostMusicCardTokenFragment on tezos_tokens {
    id
    nodeId
    title
    description
    artifact_uri
    thumbnail_uri
    mime_type
    tokens_sharesCollection {
      edges {
        node {
          share_count
        }
      }
    }
    accounts {
      id
      nodeId
      identities {
        profilesCollection {
          edges {
            node {
              username
            }
          }
        }
      }
      ...UserItemFragment
    }
  }
`;

export default function PostMusicCard({
  tokenKey,
}: {
  tokenKey: PostMusicCardTokenFragment$key;
}) {
  const token = useFragment(PostMusicCardTokenFragment, tokenKey);

  const isPlayable =
    convertGqlMimeTypetoMimeType(token?.mime_type || undefined) ===
      MimeType.VIDEO ||
    convertGqlMimeTypetoMimeType(token?.mime_type || undefined) ===
      MimeType.AUDIO;

  return (
    <TokenPageLink
      tokenId={token.id}
      username={
        token.accounts.identities.profilesCollection?.edges?.[0]?.node
          ?.username || undefined
      }
      className={styles.musicCard}
    >
      <div key={token.id}>
        <Avatar
          src={getImageUrl(token.thumbnail_uri || '')}
          alt=""
          className={styles.icon}
          onError={e => {
            e.currentTarget.src = '/poster.svg';
          }}
          key={token.id}
          observe
        />
        <div className={styles.content}>
          <div>
            {isPlayable && (
              <Button icon className={styles.playButton}>
                <MemoIcon Component={'play-circle-fill'} size={42} />
              </Button>
            )}
            <div className={styles.info}>
              <div>
                {token?.accounts && (
                  <UserItem account={token.accounts} hideIcon />
                )}
              </div>
              <div>{token.title}</div>
            </div>
          </div>
        </div>
      </div>
    </TokenPageLink>
  );
}
