/**
 * @generated SignedSource<<2f615a46f025a8763376724980493766>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostCardTokenFragment$data = {
  readonly accounts: {
    readonly identities: {
      readonly profilesCollection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly username: string | null;
          };
        }>;
      } | null;
    };
  };
  readonly description: string | null;
  readonly id: string;
  readonly nodeId: string;
  readonly thumbnail_uri: string;
  readonly title: string;
  readonly tokens_sharesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly share_count: string;
      };
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"PostDefaultCardTokenFragment" | "PostMinimalCardTokenFragment" | "PostMusicCardTokenFragment" | "PostTextCardTokenFragment">;
  readonly " $fragmentType": "PostCardTokenFragment";
};
export type PostCardTokenFragment$key = {
  readonly " $data"?: PostCardTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostCardTokenFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeDefault"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeMinimal"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeMusic"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeText"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardTokenFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nodeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "tokens_sharesConnection",
      "kind": "LinkedField",
      "name": "tokens_sharesCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "tokens_sharesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "tokens_shares",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "share_count",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "identities",
          "kind": "LinkedField",
          "name": "identities",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "profilesConnection",
              "kind": "LinkedField",
              "name": "profilesCollection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profilesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "profiles",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "username",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "includeText",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PostTextCardTokenFragment"
        }
      ]
    },
    {
      "condition": "includeMinimal",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PostMinimalCardTokenFragment"
        }
      ]
    },
    {
      "condition": "includeMusic",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PostMusicCardTokenFragment"
        }
      ]
    },
    {
      "condition": "includeDefault",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PostDefaultCardTokenFragment"
        }
      ]
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};

(node as any).hash = "923b2a3ac57fde23f86d131362ddabee";

export default node;
