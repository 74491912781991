import React from 'react';
import styles from '@/view/styles/components/PostActions/blocs/PostActionsViewOnly.module.scss';
import RemixIcon from '../../RemixIcon';
import clsx from 'clsx';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {PostActionsViewOnlyTokenFragment$key} from './__generated__/PostActionsViewOnlyTokenFragment.graphql';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';

const PostActionsViewOnlyTokenFragment = graphql`
  fragment PostActionsViewOnlyTokenFragment on tezos_tokens {
    id
    nodeId
    tokens_reactionsCollection {
      edges {
        node {
          id
        }
      }
    }
    commentsCollection {
      totalCount
    }
    tokens_sharesCollection {
      edges {
        node {
          share_count
        }
      }
    }
  }
`;

export default function PostActionsViewOnly({
  post: postKey,
  iconSize,
  labels,
  vertical,
  className,
}: {
  post: PostActionsViewOnlyTokenFragment$key;
  iconSize?: number;
  labels?: boolean | 'count';
  vertical?: boolean;
  className?: string;
}) {
  const {isMobile} = useBreakpoint();

  const post = useFragment(PostActionsViewOnlyTokenFragment, postKey);
  return (
    !isMobile && (
      <div className={clsx(styles.root, className)} data-vertical={vertical}>
        <div>
          <RemixIcon icon="heart-line" size={iconSize || 18} />
          {labels && (
            <span>{post.tokens_reactionsCollection?.edges.length || 0}</span>
          )}
        </div>
        <div>
          <RemixIcon icon="chat-4-line" size={iconSize || 18} />
          {labels && <span>{post.commentsCollection?.totalCount}</span>}
        </div>
        <div>
          <RemixIcon icon="share-forward-line" size={iconSize || 18} />
          {labels && (
            <span>
              {parseInt(
                post.tokens_sharesCollection?.edges?.[0]?.node?.share_count ||
                  '0'
              )}
            </span>
          )}
        </div>
      </div>
    )
  );
}
